<!-- 信息动态详情页-->
<template>
  <div class="harmful">
    <!-- <el-row>
      <el-col :span="21">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>当前位置：</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>信息详情</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="3">
        <div class="returns" @click="goback()" v-if="b != 1">返回列表</div>
      </el-col>
    </el-row> -->
    <div class="coments">
      <div class="tits">{{ wenzh == null ? "" : wenzh.title }}</div>
      <div class="dates">{{ wenzh == null ? "" : wenzh.createtime }}</div>
      <div class="iners" v-if="wenzh == null ? '' : wenzh.is_stage != 1">
        <div
          v-for="(item, index) in wenzh == null ? '' : wenzh.content"
          :key="index"
        >
          <p>{{ item.content }}</p>
          <img :src="item.images" alt="" />
          <a @click="tiaozhuan(item.links)" v-if="item.links != ''">{{
            item.links
          }}</a>
        </div>
      </div>
      <div v-else v-html="wenzh == null ? '' : wenzh.content">

      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/infos.js";
export default {
  data() {
    return {
      wenzh: null,
      b: 0,
    };
  },
  mounted() {
    let a = this.$store.state.loginMessage.wid;
    this.b = window.localStorage.getItem("xin");
    this.shareDetail(a);
  },
  methods: {
    tiaozhuan(wz) {
      // window.location.href = wz
      let path = window.location.protocol + "//" + wz;
      window.location.href = path;
    },
    //返回上一页
    goback() {
      this.$router.go(-1);
    },
    //详情
    async shareDetail(id) {
      let res = await API.shareDetail({
        sharesalt: id,
      }).then((res) => {
        if (res.code == 1) {
          this.wenzh = res.data;
          document.title = this.wenzh.title
        }
      });
    },
  },
  beforeDestroy() {
    window.localStorage.removeItem("tid");
    window.localStorage.removeItem("xin");
  },
};
</script>

<style scoped>
.harmful {
  width: 1200px;
  padding: 20px 0 0 0;
}

/deep/.el-breadcrumb {
  font-size: 18px;
  color: #000;
  padding: 6px 0 0;
}

/deep/.el-breadcrumb .el-breadcrumb__item:nth-child(1) .el-icon-arrow-right {
  display: none;
}

/deep/.el-breadcrumb__inner a,
/deep/.el-breadcrumb__inner.is-link {
  color: #000;
  font-weight: normal;
}

.returns {
  font-size: 18px;
  color: #000000;
  cursor: pointer;
}

.returns:hover {
  color: #444;
}

.coments {
  background: none #fff;
  padding: 50px 60px;
  margin: 20px 0 0;
}

.tits {
  font-size: 20px;
  color: #383838;
  text-align: center;
  font-weight: bold;
}

.dates {
  font-size: 12px;
  color: #000000;
  text-align: right;
  padding: 15px 0 50px;
}

.iners {
  font-size: 16px;
  color: #000000;
}

.iners p {
  text-indent: 2em;
  line-height: 30px;
  padding: 10px 0;
}

.iners img {
  text-align: center;
  width: 100%;
  text-indent: 0;
  text-align: center;
  padding: 10px 0;
}
</style>